/*** Font ***/
@font-face {
  font-family: "adventuring";
  src: url("../font/adventuring.ttf");
  src: url("../font/adventuring.ttf") format("truetype");
}

@font-face {
  font-family: "minionProRegular";
  src: url("../font/minionProRegular.ttf");
  src: url("../font/minionProRegular.ttf") format("truetype");
}
@font-face {
  font-family: "myriadProBlack";
  src: url("../font/myriadProBlack.otf");
}
@font-face {
  font-family: "myriadProBoldCond";
  src: url("../font/myriadProBoldCond.otf");
}

@font-face {
  font-family: "sassoon";
  src: url("../font/SassoonInfantCom-Regular.ttf");
}

:root {
  --bookName: minionProRegular;
  --bookNameColor: #85d4f3;
  --chapterName: adventuring;
  --activityName: adventuring;
  --btnText: adventuring;
  --textLarge: adventuring;
  --textSmall: adventuring;
  --handwriting: sassoon;

  --tabColors: #000;
  --tabOuter: #000;
  --tabBorder: #000;
}

a {
  text-decoration: none;
}

body {
  /*background: #000;*/
  font-size: 14px;
  line-height: 1.2;
  position: relative;
  padding: 0;
  margin: 0;
  user-select: none;
}

.book-name {
  font-family: var(--bookName);
  color: var(--bookNameColor);
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px 0 #fff, -0.5px -0.5px 0 #fff;
  margin: 0;
  line-height: 1.1;
  font-size: 60px;
}
.book-name.first-wrod {
  padding-left: 14px;
}

.chaper-name {
  font-family: var(--chapterName);
  color: #fff;
  margin: 0;
  line-height: 1.1;
  font-size: 30px;
  text-decoration: none;
  margin-right: -11px;
}

.chaper-name.small {
  font-size: 28px;
  line-height: 28px;
}

.activity-name {
  font-family: var(--activityName);
  color: #fff;
  margin: 0;
  font-size: 29px;
  line-height: 1.1;
}
.activity-name.medium {
  font-size: 14px;
  line-height: 1.1;
}
.activity-name.small {
  font-size: 11px;
  line-height: 1.2;
}

.text-large {
  font-family: var(--textLarge);
  margin: 0;
  font-size: 14px;
  line-height: 1.1;
  color: #fff;
}
.text-small {
  font-family: var(--textSmall);
  margin: 0;
  font-size: 12px;
  line-height: 1.1;
  color: #fff;
}

/**** Blocks *****/
.activity-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 63px 270px 0px 0;
}
.activity-head > * {
  margin: 0 4px;
}
.activity-Title{
  width: 320px;
}
.activity-name-block {
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 8px 40px;
  display: inline-block;
}
.activity-name-block.type3 {
  background: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabColors);
  padding: 6px 24px;
  opacity: inherit;
}
.activity-name-block.type4 {
  background: #9e3893;
  box-shadow: inset 0 0 8px 4px #87337e;
  padding: 4px 9px;
    padding-left: 5px;
    display: flex;
    align-items: center;
}
.wide{
  max-width: 190px !important;
}
.activity-name-block.type5 {
  background: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabColors);
  padding: 6px 24px;
  display: block;
  max-width: 120px;
  margin: -45px auto;
  position: relative;
  top: -20px;
  border: 2px solid #fff;
}
.activity-name-block.type4 p {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.activity-name-block.type2 {
  background-color: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabColors);
  padding: 6px 10px;
}
.activity-head .play-btn {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.activity-icon-wrap {
  text-align: center;
}
.activity-icon-block {
  background: #fff;
  border: 3px solid #8a268b;
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}
.activity-icon-block.type2 {
  border-color: #68bbe8;
}
.activity-icon-wrap .activity-name-block.type4 {
  position: relative;
  margin-top: -8px;
  min-width: 70px;
}
.activity-icon-block img {
  width: 37px;
  margin: 11px auto;
  display: block;
}

.activity-small-icon {
  width: 26px;
  background: #fff;
  border-radius: 100%;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  height: 18px;
}
.activity-small-icon img {
  width: 13px;
  display: block;
  vertical-align: middle;
  margin: 3px auto;
}

.dailoug-block {
  position: relative;
  background: #9e3893;
  box-shadow: inset 0 0 8px 4px #87337e;
  border: 4px solid #fff;
  max-width: 185px;
  padding: 10px 2px;
  border-radius: 35px;
}
.dailoug-block .btn-icon {
  vertical-align: middle;
  margin-left: 5px;
}
.dailoug-block p {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 70px);
  margin-left: 4px;
}
/**** Pencile Postion *****/

/**** Activity Icon And text *****/

/**** Button and Icon *****/
.info-btn {
  cursor: pointer;
  margin-right: 19px;
}
.btn-icon,
.btn-icon:hover,
.btn-icon:focus {
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  border: 2px solid #fff;
  border-radius: 100%;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
}
.btn-icon.small,
.btn-icon.small:hover,
.btn-icon.small:focus {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border: 2px solid #fff;
  margin-left: 5px;
}
.oragnge-btn,
.oragnge-btn:hover,
.oragnge-btn:focus {
  background: #ffbc31;
  box-shadow: inset 0 0 8px 4px #f38a1e;
  cursor: pointer;
}
.blue-btn,
.blue-btn:hover,
.blue-btn:focus {
  background: #ffbc31;
  box-shadow: inset 0 0 8px 4px #f38a1e;
  /* background: #2cc9f5;
  box-shadow: inset 0 0 8px 4px #128fb8; */
}
.red-btn,
.red-btn:hover,
.red-btn:focus {
  background: #eb2d92;
  box-shadow: inset 0 0 8px 4px #e52375;
}

.btn-icon img {
  width: 38px;
  margin: 0px auto 0 18px;
}
.btn-icon.small img {
  width: 20px;
  margin: 0 auto 0 14px;
}
.btn-icon.info-btn img {
  max-height: 80%;
  width: 13px;
  margin: 0 auto;
}
.btn-icon.print-btn img {
  max-height: 90%;
  width: 90%;
  margin: 0 auto;
}
.btn-icon.home-btn img {
  max-height: 73%;
  width: 73%;
  margin: 0 auto 0 9px;
}

/**** activity Assets ****/
.activity-assets {
  display: flex;
  align-items: center;
}
.activity-assets-block {
  text-align: center;
}
.activity-bubble {
  background: #87d6f7;
  border: 2px solid #68bbe8;
  padding: 8px;
}

.activity-bubble.left .activity-icon-wrap {
  position: relative;
  left: 10px;
  top: 12px;
}

.activity-bubble.right .activity-icon-wrap {
  position: relative;
  right: 10px;
  top: 12px;
}
.activity-bubble.circle {
  border-radius: 100%;
  height: 160px;
  width: 160px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  text-align: center;
}
.activity-bubble.circle.text {
  border-radius: 100%;
  padding: 20px;
}
.warm-up-thumb img{
  width: auto !important;
  margin-left: -24px;
  height: 110% !important;
}
.thumb-text{
  position: absolute;
  width: auto !important;
  color: #ffffff !important;
  /* -webkit-text-stroke: 1px #000000; */
  font-size: 14px !important;
  margin-left: 21px;
  margin-top: 114px !important;
  font-weight: lighter !important;
  background: rgb(0 0 0 / 74%);
  padding: 0 4px;
}
.activity-bubble.text div {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 0;
  background: #fff;
  border-radius: 100%;
  overflow: hidden;
}
.activity-bubble.text p {
  width: 100%;
  font-family: "adventuring";
  color: #2dace3;
  font-weight: 600;
  font-size: 9px;
}
.activity-bubble.text img {
  width: 100%;
  height: 100%;
}
.activity-assets-block .activity-name-block.type2 {
  padding: 10px 19px;
  display: block;
  margin: -32px auto 0;
  z-index: 1;
  position: relative;
  max-width: 140px;
  opacity: inherit;
}
.activity-assets-block.top .activity-name-block.type3 {
  padding: 10px 19px;
  display: block;
  margin: 0 auto -24px;
  z-index: 1;
  position: relative;
  max-width: 140px;
}
