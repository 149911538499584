/*** Activty Base ****/
.activity-base {
  max-width: 1294px;
  margin: 0 auto;
  overflow: hidden;
  /* background: url(../images/BG-images/pre-cur-a-bg-2.jpg); */
  height: 813px;
  background-size: 100% 100%;
  position: relative;
}

.activity-base-inner {
  max-width: 100%;
  padding: 20px 0 0 0;
  border-radius: 25px;
}
.pencile-image {
  position: absolute;
  top: 149px;
  left: 130px;
  width: 131px;
}
.dailoug-block-img {
  position: absolute;
  top: 150px;
  left: 240px;
  z-index: 1;
}
.activity-folder {
  padding-top: 40px;
}
.flex {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 40px;
}
.activity-footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.activity-footer .activity-icon-wrap {
  margin: -30px 20px 0;
}
.activity-footer .activity-icon-block {
  width: 70px;
  height: 70px;
}
.activity-footer .activity-icon-block img {
  width: 52px;
}
.activity-footer .activity-name-block.type2 {
  margin-top: -40px;
  min-width: 120px;
  padding: 10px;
}
/****  activity-1 *****/
.activity-1-wrap {
  /* background: url(../images/act-bg-1.png); */
  background-size: 100% 100%;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  height: 500px;
}
.activity-1 .activity-assets-block {
  margin: 46px 0 42px 0;
}
.activity-1 .activity-assets {
  margin-top: 100px;
}

/****  activity-2 *****/
.activity-2-wrap {
  /* background: #c5e9fa; */
  width: 1060px;
  margin: 0 auto;
  border-radius: 25px;
  padding: 40px 0 30px;
}
.activity-folder-bg .activity-2 {
  background-image: url(../images/bubble-bg.png);
  background-size: 100% 100%;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  height: 430px;
  margin: 44px auto 0;
  text-align: center;
}

#gameBG {
  background-image: none;
  border-radius: 30px;
  max-width: 750px;
}

.game-frame-inner{
  margin-top: 25px;
  margin-left: 35px;
  padding-left: 55px;
  height: 430px;
  width: 680px;
  background: aliceblue;
}
.activity-2 .activity-icon-wrap {
  vertical-align: middle;
  display: inline-block;
  margin-top: 60px;
  cursor: pointer;
}
.video-frame {
  background: #bdbec1;
  display: inline-block;
  width: 459px;
  margin: 65px 30px 0 55px;
  border-radius: 25px;
  vertical-align: middle;
  padding: 20px;
  box-sizing: border-box;
}
.video-frame-inner {
  background: #fff;
  display: inline-block;
  width: 419px;
  height: 258px;
  margin: 0;
  border-radius: 25px;
  vertical-align: middle;
}

.next-pre-wrap{
  height: 60px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 40px;
  margin: 0 auto;

}

.next-pre-wrap div{
  font-size: 34px;
  background: #4fbff0;
  width: 70px;
  line-height: 34px;
  height: 40px;
  color: #ffffff;
  border-radius: 12px;
  text-shadow: 2px 2px 2px black;
  text-align: center;
}
.next-pre-wrap .vid-nav{
  height: 50px;
  width: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: #4fbff0;
  cursor: pointer;
  border-radius: 20px;
}
#next{
  background-image: url(../images/next-icon.png);
}
#next::after{
  content: 'next video';
  display: block;
  position: relative;
  height: 25px;
  width: 80px;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  line-height: 25px;
  left: 60px;
  visibility: hidden;
}
.invisible{
  visibility: hidden;
  pointer-events: none;
}
.inactive{
  opacity: 0.5;
  pointer-events: none;
}
#next:hover:after{
  visibility: visible;
}
#prev{
  background-image: url(../images/prev-icon.png);
}

#prev::after{
    content: 'previous video';
    display: block;
    position: relative;
    height: 25px;
    width: 100px;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    line-height: 25px;
    left: -115px;
    visibility: hidden;
}

#prev:hover::after{
  visibility: visible;
}

.activity-2 .activity-name-block.type3 {
  display: block;
  max-width: 180px;
  margin: -37px auto;
  position: relative;
  top: -20px;
}
.activity-2 .activity-name.medium {
  font-size: 22px;
  line-height: 1.1;
}

/****  activity-2 *****/
.activity-3-wrap {
  width: 1060px;
  margin: 0 auto;
  border-radius: 25px;
  padding: 0px 0 30px;
}
.activity-3 {
  padding: 100px 60px 0;
  text-align: center;
}
.activity-3 .activity-name-block.type3 {
  display: block;
  max-width: 162px;
  margin: -37px auto;
  position: relative;
  top: -80px;
}
.activity-3 .activity-name.medium {
  font-size: 22px;
  line-height: 1.1;
}
.act-3-bubble {
  background: url(../images/bubble-icon.png) no-repeat;
  width: 285px;
  height: 198px;
  display: inline-block;
  vertical-align: top;
  margin: 0 13px;
  position: relative;
  background-size: 100% 100%;
}
.activity-3 .type2 .activity-name.medium {
  font-size: 15px;
  line-height: 1.1;
}
.activity-3 .activity-name-block {
  position: absolute;
}
.activity-3 .activity-name-block.first {
  left: 25px;
  bottom: 53px;
}
.activity-3 .activity-name-block.second {
  left: 100px;
  bottom: 6px;
}
.activity-3 .activity-name-block.third {
  right: 30px;
  bottom: 53px;
}
.act-3-bubble > .activity-name-block:first-child {
  position: relative;
  top: -10px;
  display: block;
  max-width: 100px;
  margin: 0 auto;
}
.activity-3 .activity-bubble.circle {
  width: 130px;
  height: 130px;
  background: transparent;
  border: 0;
  position: relative;
  top: -25px;
}

#vidPlayer {
  width: 100%;
  height: 260px;
}

#closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  font-size: 25px;
  color: red;
}

.vidCont {
  position: relative;
  background-color: rgba(0, 0, 0, 1);
  /* height: 790px; */
  display: flex;
  align-items: center;
  z-index: 3;
}

#iPlayer {
  width: 100%;
  height: 266px;
}

.gameIfrm {
  width: 90%;
  height: 425px;
}

.gameIcon {
  position: absolute;
  top: 50px;
  left: 435px;
}

.print-frame-inner {
  margin-top: 30px;
  height: 390px;
  overflow: auto;
}

.printablesBtn {
  margin-bottom: 5px;
}

.printablesBtn a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.printablesBtn img {
  margin-left: -25px;
}

.print-inner {
  font-family: var(--activityName);
  color: #fff;
  background-color: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabOuter);
  border: 1px solid var(--tabBorder);
  border-radius: 20px;
  padding: 10px;
  width: 400px;
  font-size: 18px;
}

.info-dialog {
  position: absolute;
  display: flex;
  width: 300px;
  height: 110px;
  background-image: url("../images/dialog-1.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  left: 248px;
  top: 165px;
  font-family: myriadProBlack;
  color: #fff;
  z-index: 2;
}

.info-dialog :first-child img {
  width: 60px;
}
.info-dialog :nth-child(2) {
  width: 170px;
}

#letterCont {
  width: 950px;
  margin: 40px 200px;
}

.letterBtn {
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-right: 35px;
  margin-bottom: 60px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 40px;
  font-family: sassoon;
  border: none;
  cursor: pointer;
}

.joined{
  width: 150px;
  border-radius: 40px;
}
.join-letter-formed{
  border-radius: 0 !important;
}
.letterBtn img {
  position: absolute;
  margin-top: 20px;
  margin-left: 15px;
  width: 35px;
  transform: scaleX(-1);
  cursor: pointer;
  border-radius: 50%;
}
.joined .pencil-icon-small {
  
  margin-top: 15px;
  margin-left: 35px;
  
}

.letterFormText {
  background: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabColors);
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 10px 40px;
  font-family: var(--activityName);
  color: #fff;
  font-size: 20px;
  margin: 50px 0px 15px 400px;
  width: 430px;
}

#letterFormvidCont {
  position: relative;
  top: 300px;
  left: -150px;
  text-align: left;
  width: 755px;
  z-index: 1;
}

#letterFormvidCont img {
  width: 60px;
  margin-left: 240px;
  position: absolute;
  z-index: 1;
  transform: scaleX(-1);
}

.letterFormbodyTxt {
  background: var(--tabColors);
  box-shadow: inset 0 0 8px 4px var(--tabColors);
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 10px 40px;
  font-family: var(--activityName);
  color: #fff;
  font-size: 20px;
  top: 230px;
  right: 235px;
  position: absolute;
  width: 250px;
  z-index: 1;
}

.upperLowerCont{
  font-family: var(--activityName);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 150px;
  margin-top: 120px;
}

.upperLowerCont div{
  color: #fff;
  width: 220px;
}

.Join2, .Join3{
  /* width: 300px !important; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upperLowerCont img{
  width: 115px;
}
.Join2 img, .Join3 img{
  width: 150px;
  max-width: 100%;
  margin-bottom: 20px;
}

.Join2 div, .Join3 div{

}
.disabled{
  pointer-events: none;
  opacity: 0.4;
}