*{
    box-sizing: border-box;
}
.tile{
    width: 200px;
    height: 130px;
    cursor: pointer;
    margin: 0px 10px 58px 0px;
    display: inline-block;    
}
button:disabled,
button[disabled]{
  cursor: auto;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
    border: none;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  }
  .clicked{
    pointer-events: none;  
    cursor: auto;
  }
  
  .clicked.done{
    background-color: #d59248 !important;    
  }
  .clicked.done .flip-card-back{
    opacity: 0.6;
  }

  .flip-card.clicked .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .card-image{
    max-height: 100%;
    position: absolute;
    top: -3px;
    left: -4px;
  }
  .flip-card-front {
    
    color: black;
  }
  
  .flip-card-back {
    /* background-color: #2980b9; */
    background-color: #d59248;
    color: white;
    transform: rotateY(180deg);
  }